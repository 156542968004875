import React from 'react';
import ReactMarkdown from 'react-markdown'

import Grid from "@material-ui/core/Grid";
import aboutPrivacyPolicy from '../../assets/markdowns/about-privacy-policy.md';
import DefaultLayout from '../../components/layouts/default-layout';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';
import team from '../../json/team.json';
import Typography from "@material-ui/core/Typography";
import TeamTable from "../../components/tables/team-table";
import {Table, TableHead, TableRow, TableCell} from "@material-ui/core";


export default function About() {

    // set document title
    const [title, setTitle] = useDocTitle("Privacy Policy");

    // setup tracker after setting the document title
    useTracker();

    const [markdown, setMarkdown] = React.useState("");

    React.useEffect(() => {
        fetch(aboutPrivacyPolicy).then(res => res.text()).then(text => setMarkdown(text));
    }, [])




    return (
        <DefaultLayout>

          <Grid container alignItems="stretch" justify="left" spacing={1}>
            <Grid item>
              <Typography variant="h4"> TEAM </Typography>
              <a>Information about all the current/former developers and researchers that worked on this project.</a> <br/><br/>

              <Typography variant="h5"> Members </Typography> <br />

              <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Name + Website</TableCell>

                  <TableCell width="250px">E-Mail</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              {Object.keys(team)
                  .map(function (item) {
                  return team[item];
                })
                .flat()
                .map(function (item) {
                  const teamProps = {
                    name: item.name,
                    email: item.email,
                    website: item.website,
                    active: item.active
                  };

                  return (
                      <TeamTable {...teamProps} />
                  );
                })
              }
              </Table>
            </Grid>


            <Grid item>
              <Typography variant="h4"> PRIVACY POLICY </Typography>
              <div style={{ width: "100%", textAlign: "left"}}>
                <ReactMarkdown source={markdown}></ReactMarkdown>
              </div>
            </Grid>

          </Grid>
        </DefaultLayout>
    )
}