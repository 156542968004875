import { Box, Button, Collapse, Grid, Paper, Snackbar, TextField, Typography } from "@material-ui/core";
import React from "react";
import SolutionService from "../../services/api/solution-service";
import StorageService from "../../services/local-storage/storage-service";
import ActivityService from "../../services/api/activity-service";

import baseStyles from "../../css/const";
import UserSubmissionsTable from "./user-submissions-table";
import EditIcon from "@material-ui/icons/Edit";
import PublishIcon from "@material-ui/icons/Publish";
import Cancel from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomSnackbarContent from "../misc/custom-snackbar-content";
import CustomDialog from "../misc/custom-dialog";
import CustomStatusBox from "../misc/custom-status-box";

export default function UserSubmissionsTableContainer(props) {
  const classes = baseStyles();

  // count state
  const [statusCounts, setStatusCounts] = React.useState({"success":"-","running":"-","error":"-","pending":"-"});

  React.useEffect(() => {
    console.log("i execute");
    SolutionService.getSolutionStatusCount(props.activity)
      .then((response) => {
        setStatusCounts({ ...response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.activity, props.fetch]);

  // edit states
  const [edit, setEdit] = React.useState(false);
  const [method, setMethod] = React.useState("");
  const [algorithm, setAlgorithm] = React.useState("");
  const [codeURL, setCodeURL] = React.useState("");

  // snackbar states
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarStatus, setSnackbarStatus] = React.useState("success");

  // dialog states
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogOperation, setDialogOperation] = React.useState(null);

  // selected solutions
  const [selected, setSelected] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectAllExcluded, setSelectAllExcluded] = React.useState([]);

  // responses
  const [responses, setResponses] = React.useState([]);

  // user
  const user = StorageService.getUserInformation();

  const fetchAll = async () => {
    const query = {
      user__id: user.id,
      page: 1,
    };

    return SolutionService.getSolutions(query, true)
      .then((response) => {
        const _selected = response.data.results.map((solution) => {
          return {
            id: solution.id,
            benchmarkName: solution.benchmark_id,
          };
        });

        return _selected;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  };

  const buttonClicked = (changePage) => {
    if (changePage) {
      props.setPage(0);
    }

    setSnackbarOpen(true);
    setSelected([]);

    props.setFetch(!props.fetch);
  };

  // handlers
  const handlePageChange = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    props.setPage(0);
    props.setRowsPerPage(event.target.value);
  };

  // select all checkbox
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectAll(true);
      setSelected([]);
      setSelectAllExcluded([]);
    } else {
      setSelectAll(false);
      setSelectAllExcluded([]);
    }
  };

  // single checkbox
  const handleSelectClick = (event, id, benchmarkName) => {
    if (selectAll) {
      if (!event.target.checked) {
        // checkbox unchecked
        setSelectAllExcluded([...selectAllExcluded, { id: id, benchmarkName: benchmarkName }]);
      } else {
        // checkbox checked
        const _selectAllExcluded = [...selectAllExcluded];
        const idx = _selectAllExcluded.findIndex((item) => item.id === id);
        _selectAllExcluded.splice(idx, 1);
        setSelectAllExcluded(_selectAllExcluded);
      }
    } else {
      if (event.target.checked) {
        // checkbox checked
        setSelected([...selected, { id: id, benchmarkName: benchmarkName }]);
      } else {
        // checkbox checked
        const _selected = [...selected];
        const idx = _selected.findIndex((item) => item.id === id);
        _selected.splice(idx, 1);
        setSelected(_selected);
      }
    }
  };

  // deletes selected rows
  const handleDeleteClick = async (event) => {
    // loading animation
    props.setLoading(true);
    setSelectAll(false);

    let _selected = [];

    if (selectAll) {
      _selected = await fetchAll();
      _selected = _selected.filter((x) => {
        return selectAllExcluded.findIndex((y) => y.id === x.id) === -1;
      });
    } else {
      _selected = [...selected];
    }
    // map every selected row to a Promise object
    const promises = _selected.map((solution) => {
      const func = props.docker
        ? SolutionService.deleteDockerSolution(solution.id)
        : SolutionService.deleteSolution(solution.id);
      return func
        .then(() => {
          return {
            benchmarkName: solution.benchmarkName,
            status: "success",
          };
        })
        .catch((error) => {
          return {
            benchmarkName: solution.benchmarkName,
            status: "error",
            detail: error.data.detail,
          };
        });
    });

    Promise.all(promises).then((responses) => {
      const total = responses.length;
      const errors = responses.filter((x) => x.status === "error").length;

      if (errors === total) setSnackbarStatus("error");
      else if (errors > 0) setSnackbarStatus("warning");
      else setSnackbarStatus("success");

      setResponses(responses);
      setDialogOperation("Delete");

      buttonClicked(true);
      return responses;
    });
  };

  // deletes all solutions with status 'error'
  const handleDeleteFailedClick = () => {
    // first, create a template promise to a solution
    const promise = (solution) => {
      const func = props.docker
        ? SolutionService.deleteDockerSolution(solution.id)
        : SolutionService.deleteSolution(solution.id);

      return func
        .then(() => {
          return {
            benchmarkName: props.docker ? solution.docker_url : solution.benchmark_id,
            status: "success",
          };
        })
        .catch((error) => {
          return {
            benchmarkName: props.docker ? solution.docker_url : solution.benchmark_id,
            status: "error",
            detail: error.data.detail,
          };
        });
    };

    // then, query all failed solutions and delete
    const query = {
      user__id: user.id,
      evaluation_status: "error",
    };

    const getFunc = props.docker ? SolutionService.getDockerSolutions(query) : SolutionService.getSolutions(query);
    getFunc
      .then((response) => {
        const promises = response.data.results.map((solution) => {
          return promise(solution);
        });

        return Promise.all(promises);
      })
      .then((deleteResponses) => {
        const total = deleteResponses.length;
        const errors = deleteResponses.filter((x) => x.status === "error");

        if (errors === total) setSnackbarStatus("error");
        else if (errors > 0) setSnackbarStatus("warning");
        else setSnackbarStatus("success");

        setDialogOperation("Delete All Failed");
        setResponses(deleteResponses);
        buttonClicked(true);

        return deleteResponses;
      })
      .catch((error) => {
        window.location.reload();
        return error;
      });
  };

  // publishes selected solutions
  const handlePublishClick = async (event) => {
    // loading animation
    props.setLoading(true);
    setSelectAll(false);

    let _selected = [];

    if (selectAll) {
      _selected = await fetchAll();
      _selected = _selected.filter((x) => {
        return selectAllExcluded.findIndex((y) => y.id === x.id) === -1;
      });
    } else {
      _selected = [...selected];
    }

    // generate promises out of selected rows
    const promises = _selected.map((solution) => {
      const func = props.docker
        ? SolutionService.publishDockerSolution(solution.id)
        : SolutionService.publishSolution(solution.id);

      return func
        .then((response) => {
          return {
            benchmarkName: solution.benchmarkName,
            status: "success",
          };
        })
        .catch((error) => {
          return {
            benchmarkName: solution.benchmarkName,
            status: "error",
            detail: error.data.detail,
          };
        });
    });

    Promise.all(promises)
      .then((responses) => {
        // find how many of the responses have status 'error'
        const errors = responses.filter((x) => x.status === "error").length;
        const total = responses.length;

        if (errors === total) setSnackbarStatus("error");
        else if (errors > 0) setSnackbarStatus("warning");
        else setSnackbarStatus("success");

        setDialogOperation("Publish");
        setResponses(responses);
        buttonClicked();

        return responses;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  // publishes selected solutions
  const handleUnPublishClick = async (event) => {
    // loading animation
    props.setLoading(true);
    setSelectAll(false);

    let _selected = [];

    if (selectAll) {
      _selected = await fetchAll();
      _selected = _selected.filter((x) => {
        return selectAllExcluded.findIndex((y) => y.id === x.id) === -1;
      });
    } else {
      _selected = [...selected];
    }

    // generate promises out of selected rows
    const promises = _selected.map((solution) => {
      const func = props.docker
        ? SolutionService.unPublishDockerSolution(solution.id)
        : SolutionService.unPublishSolution(solution.id);

      return func
        .then((response) => {
          return {
            benchmarkName: solution.benchmarkName,
            status: "success",
          };
        })
        .catch((error) => {
          return {
            benchmarkName: solution.benchmarkName,
            status: "error",
            detail: error.data.detail,
          };
        });
    });

    Promise.all(promises)
      .then((responses) => {
        // find how many of the responses have status 'error'
        const errors = responses.filter((x) => x.status === "error").length;
        const total = responses.length;

        if (errors === total) setSnackbarStatus("error");
        else if (errors > 0) setSnackbarStatus("warning");
        else setSnackbarStatus("success");

        setDialogOperation("Unpublish");
        setResponses(responses);
        buttonClicked();

        return responses;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  // updates selected rows
  const handleEditClick = async (event) => {
    //! editing docker submission does not make sense for this version

    // loading animation
    props.setLoading(true);
    setSelectAll(false);

    let _selected = [];

    if (selectAll) {
      _selected = await fetchAll();
      _selected = _selected.filter((x) => {
        return selectAllExcluded.findIndex((y) => y.id === x.id) === -1;
      });
    } else {
      _selected = [...selected];
    }

    const promises = _selected.map((solution) => {
      return SolutionService.updateSolution(solution.id, method, algorithm, null, codeURL, null, null)
        .then((response) => {
          return {
            status: "success",
            benchmarkName: solution.benchmarkName,
          };
        })
        .catch((error) => {
          return {
            status: "error",
            benchmarkName: solution.benchmarkName,
            detail: error.data.hasOwnProperty("detail") ? error.data.detail : error.data.code_url, //TODO: more generic error detail handling?
          };
        });
    });

    Promise.all(promises).then((responses) => {
      const total = responses.length;
      const errors = responses.filter((x) => x.status === "error").length;

      if (errors === total) setSnackbarStatus("error");
      else if (errors > 0) setSnackbarStatus("warning");
      else setSnackbarStatus("success");

      setDialogOperation("Edit");
      setEdit(false);
      setMethod("");
      setAlgorithm("");
      setCodeURL("");
      setResponses(responses);
      buttonClicked();
    });
  };

  const handleDialogOpen = (event) => {
    setDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    setDialogOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleEditOpenClick = (event) => {
    setEdit(!edit);
  };

  const tableProps = {
    excluded: selectAllExcluded,
    selectAll: selectAll,
    docker: props.docker, // * docker submissions requires a different table layout
    fetch: props.fetch,
    loading: props.loading, // TODO: move this to parent component
    solutions: props.solutions, // TODO: move this to parent component
    page: props.page, // TODO: move this to parent component
    rowsPerPage: props.rowsPerPage, // TODO: move this to parent component
    count: props.count, // TODO: move this to parent component
    selected: selected,
    handlePageChange: handlePageChange,
    handleRowsPerPageChange: handleRowsPerPageChange,
    handleSelectClick: handleSelectClick,
    handleSelectAllClick: handleSelectAllClick,
  };

  return (
    <React.Fragment>
      <Grid container direction="column" alignItems="stretch" justify="center" spacing={1}>
        {/* status boxes */}
        <Grid
          key={props.activity}
          item
          container
          direction="rox"
          justify="flex-start"
          spacing={2}
          style={{ marginBottom: "8px" }}
        >
          <Grid item>Evaluation status count:</Grid>
          <Grid item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomStatusBox style={{ display: "inline-block", marginRight: "5px" }} status="success" />
              <Typography style={{ display: "inline-block" }}>{`Success: ${statusCounts["success"]}`}</Typography>
            </div>
          </Grid>

          {props.docker && (
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomStatusBox style={{ display: "inline-block", marginRight: "5px" }} status="info" />
                <Typography style={{ display: "inline-block" }}>{`Running: ${statusCounts["running"]}`}</Typography>

              </div>
            </Grid>
          )}

          <Grid item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomStatusBox style={{ display: "inline-block", marginRight: "5px" }} status="warning" />
              <Typography style={{ display: "inline-block" }}>{`Pending: ${statusCounts["pending"]}`}</Typography>
            </div>
          </Grid>
          <Grid item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomStatusBox style={{ display: "inline-block", marginRight: "5px" }} status="error" />
              <Typography style={{ display: "inline-block" }}>{`Error: ${statusCounts["error"]}`}</Typography>
            </div>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              style={{ height: "23px" }}
              onClick={() => {
                props.setFetch((x) => !x);
                props.setLoading(true);
              }}
            >
              Reload
            </Button>
          </Grid>
        </Grid>
        {/* status boxes end */}
        <Grid item container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
          {/*<Grid item>
            <Button
              className={`${classes.button} ${classes.buttonPublish}`}
              variant="contained"
              onClick={handlePublishClick}
              startIcon={<PublishIcon />}
              disabled={selected.length === 0 && !selectAll}
            >
              Publish
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={`${classes.button} ${classes.buttonDelete}`}
              variant="contained"
              onClick={handleUnPublishClick}
              startIcon={<Cancel />}
              disabled={selected.length === 0 && !selectAll}
            >
              Unpublish
            </Button>
          </Grid>
          {!props.docker && (
            <Grid item>
              <Button
                className={`${classes.button} ${classes.button}`}
                variant="contained"
                onClick={handleEditOpenClick}
                startIcon={<EditIcon />}
                disabled={selected.length === 0 && !selectAll}
              >
                Edit Metadata
              </Button>
            </Grid>
          )}*/}

          <Grid item>
            <Button
              className={`${classes.button} ${classes.buttonDelete}`}
              variant="contained"
              onClick={handleDeleteClick}
              startIcon={<DeleteIcon />}
              disabled={selected.length === 0 && !selectAll}
            >
              Delete
            </Button>
          </Grid>

          {/*<Grid item>
            <Button
              className={`${classes.button} ${classes.buttonDelete}`}
              variant="contained"
              onClick={handleDeleteFailedClick}
              startIcon={<DeleteIcon />}
            >
              Delete all infeasible Solutions
            </Button>
          </Grid>*/}

          {(selectAll || selected.length > 0) && (
            <Grid item>
              <Paper variant={"outlined"} className={`${classes.infoBox}`}>
                <span>
                  {selectAll ? props.count - selectAllExcluded.length : selected.length} submission(s) selected
                </span>
              </Paper>
            </Grid>
          )}
        </Grid>

        <Grid item>
          <Collapse in={edit} timeout="auto" unmountOnExit={true}>
            <div>
              <form noValidate autoComplete="off">
                <TextField
                  id="method"
                  placeholder="Method"
                  helperText="e.g., A* search"
                  variant="outlined"
                  value={method}
                  onChange={(event) => setMethod(event.target.value)}
                  size="small"
                  style={{ width: "200px", marginLeft: "5px" }}
                />

                <TextField
                  id="algorithm"
                  placeholder="Algorithm"
                  helperText="e.g. CommonRoad Search"
                  variant="outlined"
                  value={algorithm}
                  onChange={(event) => setAlgorithm(event.target.value)}
                  size="small"
                  style={{ width: "200px", marginLeft: "5px" }}
                />
                <TextField
                  id="codeurl"
                  placeholder="Code URL"
                  helperText="Valid URL, e.g. to github repository of the motion planner."
                  variant="outlined"
                  value={codeURL}
                  onChange={(event) => setCodeURL(event.target.value)}
                  size="small"
                  style={{ width: "200px", marginLeft: "5px" }}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={handleEditClick}
                  style={{ marginLeft: "5px" }}
                >
                  Change
                </Button>
              </form>
            </div>
          </Collapse>
        </Grid>
      </Grid>

      {/** props children goes here */}
      <UserSubmissionsTable {...tableProps} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarStatus === "success" ? 10000 : null}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CustomSnackbarContent
          status={snackbarStatus}
          handleDialogOpen={handleDialogOpen}
          handleSnackbarClose={handleSnackbarClose}
        />
      </Snackbar>

      <CustomDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        contents={responses}
        dialogOperation={dialogOperation}
      />
    </React.Fragment>
  );
}
