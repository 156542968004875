import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Hidden,
  Divider,
  Grid,
  Backdrop, TablePagination
} from "@material-ui/core";

import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import baseStyles from '../../css/const';
import ScenarioRankingRow from "./scenario-ranking-row";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SolutionRankingRow from "./solution-ranking-row";

export default function SolutionRankingTable(props) {
  const classes = baseStyles();
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;
  const count = props.count;
  const solutions = props.solutions || [];
  const [open, setOpen] = React.useState(false);

  const handlePageChange = props.handlePageChange;
  const handleRowsPerPageChange = props.handleRowsPerPageChange;

  return (
    <Grid container direction='column' alignItems='stretch' justify='center'>
      <Grid item>
        <Paper className={classes.paper}>
          <Table aria-label="collapsible-table" size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{paddingBottom: "0px" }}>User</TableCell>
                <Hidden xsDown>
                  <TableCell>Version</TableCell>
                </Hidden>

                <Hidden smDown>
                  <TableCell style={{paddingBottom: "0px" }}>Submitted&nbsp;Date</TableCell>
                </Hidden>

                <Hidden mdDown>
                  <TableCell style={{paddingBottom: "0px" }}>Tags</TableCell>
                </Hidden>

                <Hidden mdDown>
                  <TableCell style={{paddingBottom: "0px" }}>Solution</TableCell>
                </Hidden>


              </TableRow>
            </TableHead>

            <TableBody style={{
            position: 'relative',
          }}>
            {<Backdrop style={{position: "absolute", zIndex: 1, opacity: 0.3}}></Backdrop>}
            {Object.keys(solutions)
              .map(function(item) {
                return solutions[item];
              }).flat()
              .map(function(item){
                const solutionRankingProp = {
                  solution_id: item.solution_id,
                  version: item.version,
                  cost: item.cost,
                  costFunction: item.costFunction,
                  computationTime: item.computationTime,
                  date: item.date,
                  json: item.json,
                  html:item.html,
                  username: item.user.username,
                  tags: item.tags,
                  notes: item.notes
                };
                return (
                    <SolutionRankingRow
                      className={classes.styledRow}
                      {...solutionRankingProp}/>
                )
              })
            }
          </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
