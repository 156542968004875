import axios from "axios";
import getInstance from "../instances";

export default class SolutionCrokService {

  static API_URL = process.env.REACT_APP_API_URL + "solutioncrok/";

  static async getSolutions() {
    return axios.get(`${this.API_URL}`, {
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  static async getSolutionToTask(task_id, params)  {
    return axios.get(`${this.API_URL}ranking/${task_id}/`, {
      params: params
    })
      .then((response) => {return response;})
      .catch((error) => {return error.response;});
  }

  static async getSolutionSummary(task_id) {
    return getInstance()
      .get(`${this.API_URL}ranking/${task_id}/summary/`, {
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}
