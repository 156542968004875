import React from "react";
import Grid from "@material-ui/core/Grid";

import baseStyles from "../../css/const";
import { Button, Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import DefaultLayout from "../../components/layouts/default-layout";

export default function DemoTest() {

  function demoPassId(id) {
    fetch(`http://localhost:8000/api/democlick/?id=1`, {
      method:'POST',
    }).then((response) =>{
    })
  }
  
  const classes = baseStyles();

  const render_topic_box = () => {
    return (
      <Grid item xs={"auto"}>
        {/*<Button type={"submit"} variant="contained" className={classes.submit} href={"http://localhost:8000/api/democlick/?id=1"}>*/}
        <Button type={"submit"} variant="contained" className={classes.submit} onClick={() => demoPassId(1)}>
          Click
        </Button>
      </Grid>
    );
  };

    return (
    <body className={classes.body}>
      <DefaultLayout>
        <main>
          <Grid container direction="column" justify="center" spacing={2}>

            {/* Button */}
            {render_topic_box({
            })}
          </Grid>
        </main>
      </DefaultLayout>
    </body>
  );
}
