import React from "react";

import { AppBar, Grid, Paper, Tab, Tabs, Typography, Link } from "@material-ui/core";
import TabPanel from '../../../components/tab-panel';
import LargeLayout from "../../../components/layouts/large-layout";
import useDocTitle from "../../../hooks/use-doc-title";
import useTracker from "../../../hooks/use-tracker";

import baseStyles from "../../../css/const";
import QuickAccess from "../../../components/quick-access";
import AllSubmissions from "./tabs/all-submissions";
import ChallengeSubmissons from "./tabs/challenge-submissions";

export default function SubmissionsMain(props) {
  const classes = baseStyles();
  
  // check whether router props were set
  const tabName = (props.location.state) ? props.location.state.activeTab : 'default';
  const activityId = (props.location.state) ? props.location.state.activityId: null;

  // set document title
  const [title, setTitle] = useDocTitle("My Submissions");

  // setup tracker after setting the document title
  useTracker();

  // appbar states
  const tabValue_ = (tabName === 'default') ? 0 : 1;
  const [tabValue, setTabValue] = React.useState(tabValue_);

  return (
    <LargeLayout>
      <Grid container spacing={2} direction="row" justify="center">
        <Grid item xs={12} lg={10} xl={10}>
          <AppBar position="static" color="white">
            <Tabs
              value={tabValue}
              onChange={(event, val) => setTabValue(val)}
              indicatorColor="primary"
              textColor="primary"
              backGroundColor="primary"
            >
              <Tab label="All Submissions" style={{ outline: "none", marginRight: "15px" }} />
              {/**<Tab label="Challenge Submissions" style={{ outline: "none", marginRight: "15px" }} /> */}
            </Tabs>
          </AppBar>

          <div style={{ marginTop: "5px" }} />

          {tabValue === 0 && (
            <TabPanel>
              <AllSubmissions />
            </TabPanel>
          )}

          {tabValue === 1 && (
            <TabPanel>
              <ChallengeSubmissons activityId={activityId}/>
            </TabPanel>
          )}
        </Grid>

        <Grid item xs={12} lg={2} xl={2}>
          <Grid container direction="column" justify="flex-start" spacing={2}>
            <Grid item>
              <QuickAccess />
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography align="left">
                  Please check locally first if solutions are valid, e.g. with Timor's <Link href={"https://timor-python.readthedocs.io/en/latest/autoapi/timor/task/Solution/index.html#timor.task.Solution.SolutionBase._check_valid"}>Solution.valid()</Link>.
                  Submissions are checked for the constraints included in the solved task. This <i>might</i> entail
                  solving all goals, lack of collisions, or respecting the joint limits.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LargeLayout>
  );
}
