import {Paper, Typography} from '@material-ui/core';
import React from 'react';
import DefaultLayout from '../../components/layouts/default-layout';
import SolutionRankingsTableContainer from '../../components/rankings/global-ranking/solution-rankings-table-container';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';

import baseStyles from '../../css/const';
import Grid from "@material-ui/core/Grid";
import ScenarioRankingContainer from "./scenario-ranking-container";

export default function ScenarioRanking() {
  const classes = baseStyles();

  const [title, setTitle] = useDocTitle('Solutions Ranking');
  useTracker();

  return (
    <DefaultLayout>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <Typography className={classes.title}>
            Ranking
          </Typography>
        </Grid>

        <Grid item>
          <ScenarioRankingContainer />
        </Grid>

      </Grid>
    </DefaultLayout>
  )

}