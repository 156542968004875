import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import baseStyle from "../../css/const";
import tools from "../../json/tools.json";
import InfoPaper from "../../components/cards/info-card";
import Paper from "@material-ui/core/Paper";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import robots from "../../json/robots.json"
import team from "../../json/team.json";
import TeamTable from "../../components/tables/team-table";
import RobotsTable from "../../components/robots/robots-table";

export default function Robots() {
  // set document title
  const [title, setTitle] = useDocTitle("Robots");

  // setup tracker after setting the document title
  useTracker();

  const classes = baseStyle();

  return (
    <DefaultLayout>
      <div className="container">

          <div className="row">
            <div className="col">
              <h1 className="display-5">Robots</h1>
              <p>A transformer from CoBRA's module description to URDF is available <a target="_blank" href="https://pypi.org/project/timor-python/">here</a> or use the API that powers the "Example URDF" buttons. </p>
            </div>
          </div>

          {Object.keys(robots)
            .map(function (item) {
              return robots[item];
            })
            .flat()
            .map(function (item) {
              const robotsProps = {
                name: item.name,
                description: item.description,
                image: item.image,
                download: process.env.REACT_APP_API_URL + item.download,
                urdf: process.env.REACT_APP_API_URL + item.urdf,
              };

              return (
                <Grid
                  item
                  align="left"
                >
                  <RobotsTable {...robotsProps} />
                </Grid>
              );
            })
          }

          </div>

    </DefaultLayout>


  );
}
