import getInstance from "../instances";
import StorageService from "../local-storage/storage-service";
import axios from "axios";

export default class ScenarioCrokService {

  //static API_URL = 'http://localhost:8000/api/' + "scenariocrok/";
  static API_URL = process.env.REACT_APP_API_URL + "scenariocrok/";

  static async getScenarios(params = {}) {
    //const instanceType = getInstanceType();

    return getInstance()
      .get(this.API_URL, {
        params: params
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    /*
    return axios.get(`${this.API_URL}`, {
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

     */
  }


    static async getScenariosForRanking(params = {}) {
    return getInstance()
      .get(`${this.API_URL}?ranking=True`, {
        params: params
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  static async getScenariosSummary() {
    //const instanceType = getInstanceType();

    return getInstance()
      .get(`${this.API_URL}metadata/summary/`, {
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }



  /*
  static async getAllScenarioIds() {
    const instanceType = getInstanceType();

    return getInstance(instanceType)
     .get(`${this.API_URL}scenario_ids/`, {
        //params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

   */

}


const getInstanceType = () => {
  // Check if function is called by an admin. If so use the axiosAuthInstance
  /*
  const user = StorageService.getUserInformation();

  if(user !== null && user.is_staff) {
    return "admin";
  } else {
    return "default"
  }

   */
  return "default"
}


