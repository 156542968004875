import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@material-ui/core";
import {GrNew, GrBook, GrMail, GrGroup, GrHelp, GrTable} from "react-icons/gr";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  
  listItem :{
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    textDecoration: 'none',
    outline: '0 !important'
  },

  listItemIcon: {
    minWidth: '30px'
  }
}));
  
export const QuickAccessCompetition2021 = () => {

  const classes = useStyles();
  return (
    <Paper>
      <List>
        <ListItem className={classes.listItem} button component={Link} to={'/competition/2021/announcement'}>
          <ListItemIcon className={classes.listItemIcon}><GrNew /></ListItemIcon>
          <ListItemText> Announcement </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component={Link} to={'/competition/2021/details'}>
          <ListItemIcon className={classes.listItemIcon}><GrBook /></ListItemIcon>
          <ListItemText> Rules and Details</ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component={Link} to={'/challenges/id/7b3332ab-116c-4095-9334-dbd857d89844'}>
          <ListItemIcon className={classes.listItemIcon}><GrTable /></ListItemIcon>
          <ListItemText> Leaderboard Phase1</ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component={Link} to={'/challenges/id/b4d727de-0283-43e6-834d-4f5a386f2cf8'}>
          <ListItemIcon className={classes.listItemIcon}><GrTable /></ListItemIcon>
          <ListItemText> Leaderboard Phase 2</ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component={Link} to={'/competition/2021/organizers'}>
          <ListItemIcon className={classes.listItemIcon}><GrGroup /></ListItemIcon>
          <ListItemText> Organizers </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component='a' href='https://docs.google.com/forms/d/e/1FAIpQLSfr4p5VVKen-v-Jzg3G-NRn2oVYE8mQbhlh5c7HPJCRoUydCw/viewform'>
          <ListItemIcon className={classes.listItemIcon}><GrMail /></ListItemIcon>
          <ListItemText> Newsletter </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component='a' href='https://commonroad.in.tum.de/forum/c/Discuss-about-Competition-for-Motion-Planning-of-Autonomous-Vehicles-2021/'>
          <ListItemIcon className={classes.listItemIcon}><GrHelp /></ListItemIcon>
          <ListItemText> Forum </ListItemText>
        </ListItem>
      </List>
    </Paper>
  )
}

export const QuickAccessCompetition2022 = () => {

  const classes = useStyles();
  return (
    <Paper>
      <List>
        <ListItem className={classes.listItem} button component={Link} to={'/competition/2022/announcement'}>
          <ListItemIcon className={classes.listItemIcon}><GrNew /></ListItemIcon>
          <ListItemText> Announcement </ListItemText>
        </ListItem>
        {/* <ListItem className={classes.listItem} button component={Link} to={'/competition/2021/details'}>
          <ListItemIcon className={classes.listItemIcon}><GrBook /></ListItemIcon>
          <ListItemText> Rules and Details</ListItemText>
        </ListItem> */}
        {/* <ListItem className={classes.listItem} button component={Link} to={'/challenges/id/7b3332ab-116c-4095-9334-dbd857d89844'}>
          <ListItemIcon className={classes.listItemIcon}><GrTable /></ListItemIcon>
          <ListItemText> Leaderboard Phase1</ListItemText>
        </ListItem> */}
        {/* <ListItem className={classes.listItem} button component={Link} to={'/challenges/id/b4d727de-0283-43e6-834d-4f5a386f2cf8'}>
          <ListItemIcon className={classes.listItemIcon}><GrTable /></ListItemIcon>
          <ListItemText> Leaderboard Phase 2</ListItemText>
        </ListItem> */}
        <ListItem className={classes.listItem} button component={Link} to={'/competition/2022/organizers'}>
          <ListItemIcon className={classes.listItemIcon}><GrGroup /></ListItemIcon>
          <ListItemText> Organizers </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component='a' href='https://docs.google.com/forms/d/e/1FAIpQLSfr4p5VVKen-v-Jzg3G-NRn2oVYE8mQbhlh5c7HPJCRoUydCw/viewform'>
          <ListItemIcon className={classes.listItemIcon}><GrMail /></ListItemIcon>
          <ListItemText> Newsletter </ListItemText>
        </ListItem>
        {/* <ListItem className={classes.listItem} button component='a' href='https://commonroad.in.tum.de/forum/c/Discuss-about-Competition-for-Motion-Planning-of-Autonomous-Vehicles-2021/'>
          <ListItemIcon className={classes.listItemIcon}><GrHelp /></ListItemIcon>
          <ListItemText> Forum </ListItemText>
        </ListItem> */}
      </List>
    </Paper>
  )
}
