import React, { useRef } from "react";

import {
  Typography,
  Card,
  CardContent,
  Collapse,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  Divider,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import DefaultLayout from "../../components/layouts/default-layout";
import LargeLayout from "../../components/layouts/large-layout";
import QuickAccess from "../../components/quick-access";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import Link from "@material-ui/core/Link";
import SolutionUpload from "../../components/solution/solution-upload";
import ScenariosCrokContainer from "../scenarioscrok/scenarioscrok-container";



const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },

  main: {
    width: "100%",
    margin: "0 auto",
  },

  "row-1": {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

  },

  "docker-submissions": {
    marginTop: '20px',
    marginBottom: '20px',
  },

  item: {
    flexBasis: "45%",
  },

  "submission-format": {
    marginTop: "20px",
  },

  "xml-formats": {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  "cost-and-vehicle-models": {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

export default function VisualizationCrok() {
  // set document title
  const [docTitle, setDocTitle] = useDocTitle("Solution Upload");

  // setup tracker after setting the document title
  useTracker();

  const classes = useStyles();

  return (
    <LargeLayout>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={30} xl={12} alignContent={"center"}>
          <div id="main" className={classes.main}>
            <div id="row-1" className={classes["row-1"]}>
              <div id="column-1" className={classes.item}>
                <Typography variant="h4" align={"center"}>Upload your solution here</Typography>
                <Typography variant="body1" style={{ marginBottom: "10px" }}>
                  Results to Crok benchmarks have to be uploaded as .xml files according to format specified
                  below. After submitting your solution, you can check the result of the evaluation under{" "}
                  <Link href={"/user/submissions"}>my submissions</Link>.
                </Typography>
                <SolutionUpload/>
              </div>


            </div>

            <Divider />

            <div id="submission-format" className={classes["submission-format"]}>
              <Typography variant="h4">Scenario List</Typography>

              <Grid item>
                  <ScenariosCrokContainer />
              </Grid>





            </div>
          </div>
        </Grid>


      </Grid>
    </LargeLayout>
  );
}
