import getInstance from "../instances";
import TokenService from "./token-service";
import AuthenticationService from "./authentication-service";

export default class UserService {
  static API_URL = process.env.REACT_APP_API_URL + "user/";

  static async get(userId) {
    return getInstance("auth")
      .get(`${this.API_URL}${userId}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async create(
    email,
    username= null,
    password,
    passwordConfirm,
    //firstName = null,
    //lastName = null,
    country = null,
    institution = null
  ) {
    return getInstance()
      .post(
        this.API_URL,
        {
          email: email,
          username: username,
          password: password,
          password_confirm: passwordConfirm,
          //first_name: firstName,
          //last_name: lastName,
          profile: {
            country: country,
            instituition: institution,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async update(
    uuid,
    username,
    //firstName,
    //lastName,
    country,
    institution
  ) {
    const data = {
      username: username,
      //first_name: firstName,
      //last_name: lastName,
      profile: {
        country: country,
        institution: institution,
      },
    };
    return getInstance('auth')
      .patch(`${this.API_URL}${uuid}/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async activate(token) {
    return getInstance()
      .post(`${this.API_URL}activate/`, { token })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async resendActivation(email) {
    return getInstance()
      .post(`${this.API_URL}resend_activation/`, { email })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async changePassword(
    uuid,
    oldPassword,
    password,
    passwordConfirm,
  ) {
    return getInstance('auth')
      .patch(
        `${this.API_URL}${uuid}/change_password/`,
        {
          old_password: oldPassword,
          password: password,
          password_confirm: passwordConfirm,
        }
      )
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async recoverPassword(email) {
    return getInstance()
      .post(`${this.API_URL}recover_password/`, { email })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async resetPassword(token, password, passwordConfirm) {
    return getInstance()
      .post(
        `${this.API_URL}reset_password/`,
        {
          token: token,
          password: password,
          password_confirm: passwordConfirm,
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async totalUserCount() {
    return getInstance()
      .get(`${this.API_URL}count/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}
