import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import BlogCard from "../components/cards/blog-card";
import StatisticCard from "../components/cards/statistic-card";

import { Typography, Button, Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";

import baseStyles from "../css/const.js";
import customCarousel from "../css/custom-carousel.css"
import tools from "../json/tools.json";
import news from "../json/news.json";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DefaultLayout from "../components/layouts/default-layout";
import useTracker from "../hooks/use-tracker";
// import UserService from "../services/api/user-service";
import useDocTitle from "../hooks/use-doc-title";
import UserService from "../services/api/user-service";
import SolutionService from "../services/api/solution-service";
import ScenarioService from "../services/api/scenario-service";
import Carousel from 'react-bootstrap/Carousel';
import Box from "@material-ui/core/Box";

export default function Index() {
  const [title, setTitle] = useDocTitle("CoBRA");

  useTracker();

  const classes = baseStyles();
  //const classesCarousel = customCarousel;

  const [showNews, setShowNews] = React.useState(false);
  const [showTools, setShowTools] = React.useState(false);
  const [userCount, setUserCount] = React.useState(1029);
  const [scenarioCount, setScenarioCount] = React.useState(5705);
  const [submissionCount, setsubmissionCount] = React.useState(239632);

  const renderNewButton = () => {
    if (news.length > 4) {
      return (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowNews(!showNews)}
            className={classes.button}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            View {showNews ? "Less" : "More"}
          </Button>
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };

  const [index, setIndex] = React.useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  /*
  React.useEffect(() => {
    UserService.totalUserCount().then((response) => {
      setUserCount(response.data.count);
    });
    ScenarioService.getScenarioCount().then((response) => {
      setScenarioCount(response.data);
    });
    SolutionService.totalSolutionCount().then((response) => {
      setsubmissionCount(response.data.count);
    });
  }, []);

   */

  return (
    <DefaultLayout>
      <Grid container direction="column" alignItems="stretch" justify="center" spacing={5}>
        {/** Welcome */}
        <Grid item>
          <Grid item container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                <h2 className="display-4 text-center">Welcome to <b>CoBRA</b></h2>
            </Grid>
          </Grid>
          <Grid item container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <img src={require("../assets/images/crok/CoBRA_workflow.png").default} style={{width: "100%", height: "auto", padding: 10}}/>
            </Grid>
            <Grid item xs={12} md={6} style={{ maxWidth: "100%", paddingLeft: "10px", paddingRight: "10px"}}>
              <pi style={{width: "100%"}}>
                The <b>Co</b>mposable <b>B</b>enchmark for <b>R</b>obotics <b>A</b>pplications (CoBRA) is a benchmark suite for modular robot synthesis, selection, and trajectory planning.
                It contains detailed and open descriptions of <a href={"/crok-documentation/robot"}>modular robots</a>, <a href={"/crok-documentation/scenario"}>tasks</a> to fulfill, and <a href={"/crok-documentation/solution"}>costs</a> to optimize.
                Each component can be recombined to specify individual benchmarks (add link).
                A specific task can be selected on this <a href={"/tasks"}>website</a> and then solved by your motion planners and optimizers.
                A created <a href={"/crok-documentation/solution"}>solution</a> can then be <a href={"/new-submission"}>uploaded</a> to CoBRA where it will be verified and <a href={"/solutions/ranking"}>compared to other solutions</a>.
                We keep track of all <a href={"/publications"}>publications using CoBRA</a>.
              </pi>
            </Grid>
          </Grid>
        </Grid>

        {/** Benchmark Parts */}
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Main Benchmark Parts</Typography>
        </Grid>
        <Grid container spacing={{xs: 1, md: 3}} columns={{xs: 4, md: 12}}>
          {/** Tasks */}
          <Grid container direction="column" justify="center" xs={12} md={4}>
            <Grid item justify-content="start">
              <h5 style={{color: "black", padding: 10}}>Tasks</h5>
              <Typography style={{padding: 10}}>
                CoBRA contains diverse and uniquely defined tasks.
                They contain an environment, various goals to achieve and a set of constraints the robot needs to obey.
                Find the <a href={"/crok-documentation/scenario"}>complete documentation</a> and <a href={"/tasks"}>browse availalable tasks</a>.
                Contact us to include <a href={"mailto:cobra@cps.cit.tum.de?subject=Suggestion of new Task"}>your tasks</a>.
              </Typography>
            </Grid>
            <Grid item>
              <img
                className="img-fluid d-block w-100"
                src={require("../assets/images/crok/TaskExample.png").default}
                alt="Example Task."
                style={{width: "auto", maxHeight: "400px", objectFit: "contain", padding: 10}}
              />
            </Grid>
          </Grid>
          {/** Robots */}
          <Grid container direction="column" justify="top" xs={12} md={4}>
            <Grid item justify-content="start">
              <h5 style={{color: "black", padding: 10}}>Robots</h5>
              <p style={{color: "black", padding: 10}}>
                CoBRA defines a <a href={"crok-documentation/robot"}>novel format for modular robot description</a> and contains a selection of <a href={"/robots"}>different robots</a> in a common format.
                You can also <a href={"mailto:cobra@cps.cit.tum.de?subject=Suggestion of new Robot"}>suggest new robots</a> to be added to the benchmark.
              </p>
            </Grid>
            <Grid item>
              <img
                className="img-fluid d-block w-100"
                src={require("../assets/images/crok/RobotExample.png").default}
                alt="Example Robot."
                style={{width: "auto", maxHeight: "400px", objectFit: "contain", padding: 10}}
              />
            </Grid>
          </Grid>
          {/** Solutions */}
          <Grid container direction="column" justify="center" xs={12} md={4}>
            <Grid item justify-content="start">
              <h5 style={{color: "black", padding: 10}}>Solutions</h5>
              <p style={{color: "black", padding: 10}}>
                CoBRA allows to centrally <a href={"/new-submission"}>evaluate and share</a> solutions of each composed benchmark.&nbsp;
                 <a href={"/solutions/ranking"}>Rankings</a> of each are public.<br/>
              </p>
            </Grid>
            <Grid item>
              <img
                className="img-fluid d-block w-100"
                src={require("../assets/images/crok/SolutionExample.png").default/*TODO Add link to sample html*/}
                alt="Example Solution."
                style={{width: "auto", maxHeight: "400px", objectFit: "contain", padding: 10}}
              />
            </Grid>
          </Grid>
        </Grid>

        {/** News */}
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>News</Typography>
        </Grid>

        <Grid item container spacing={2}>
          {news.slice(0, 3).map(function (item) {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <BlogCard title={item.title} text={item.description} date={item.date} href={item.link}></BlogCard>
              </Grid>
            );
          })}
        </Grid>

        <Grid item>
          <Collapse in={showNews}>
            <Grid container spacing={2}>
              {news.slice(3).map(function (item) {
                return (
                  <Grid item xs={12} sm={6} md={4}>
                    {/*<Collapse in={showNews}>*/}
                    <BlogCard title={item.title} text={item.description} date={item.date} href={item.link} />
                    {/*</Collapse>*/}
                  </Grid>
                );
              })}
            </Grid>
          </Collapse>
        </Grid>

        {renderNewButton()}

        {/** Tools */}
        {/*<Grid item container direction="row" justify="center" alignItems="stretch" spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>Tools</Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              {Object.keys(tools)
                .slice(0, 3)
                .map(function (item) {
                  const general = tools[item].general;

                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <BlogCard title={general.title} text={general.short_description} href={general.href} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>

          <Grid item>
            <Collapse in={showTools}>
              <Grid container spacing={2}>
                {Object.keys(tools)
                  .slice(3, -1)
                  .map(function (item) {
                    const general = tools[item].general;

                    return (
                      <Grid item xs={12} sm={6} md={4}>
                        <BlogCard title={general.title} text={general.short_description} href={general.href} />
                      </Grid>
                    );
                  })}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>*/}

        {/*<Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowTools(!showTools)}
            className={classes.button}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            View {showTools ? "Less" : "More"}
          </Button>
        </Grid>*/}

        {/*<Grid item xs={12}>
          <Typography className={classes.subtitle}>Forum</Typography>
        </Grid>*/}

        {/** Forum */}
        {/*<Grid item xs={12}>
          <Typography variant="body1" align="center" style={{ fontWeight: "500" }}>
            Visit our forum to get help or give feedback.
          </Typography>
        {/*</Grid>*/}

        {/*<Grid item xs={12}>
          <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_FORUM_URL}>
            <Button
              variant="contained"
              className={classes.button}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              Visit
            </Button>
          </a>
        </Grid>*/}
      </Grid>
    </DefaultLayout>
  );
}
