import React from 'react';
import { makeStyles} from "@material-ui/core/styles";
import {TableCell, TableRow} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: 12,
    },
}));


export default function TeamTable(props){
    const classes = useStyles();
    const {name, email, website, active} = props;
    const email_href = "mailto:" + email;


  return (
    <TableRow>
      <TableCell>
        { {website} == null?
          <a>{name}</a> : <a href={website}>{name}</a>
        }
      </TableCell>

      <TableCell><a href={email_href} >{email}</a></TableCell>
      <TableCell>{active === true ? "Current" : "Former"}</TableCell>
    </TableRow>
  )
}