import React, {useEffect} from "react";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import baseStyles from "../../css/const";
import SCENARIOCROK_ENUMS from './scenariocrok-enums';
import {Divider, TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    minHeight: '37px'
  },

  chips: {
    display: "flex",
    maxWidth: '100%'
  },

  rangeSlider: {
    padding: "10px",
  },

  typography: {
    fontSize: "14px",
    marginBottom: "0px"
  },

  button: {
    marginRight: "10px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    }
  },
}));


function SelectFilter(props) {

  const classes = useStyles();

  // properties
  const options = props.options;
  const reference = props.reference;

  // states
  const [value, setValue] = React.useState(props.reference.current);

  React.useEffect(() => {
    reference.current = value;
  }, [value])

  if (value != props.reference.current) {
    setValue(props.reference.current);
  }

  // handler
  const handler = (event) => {
    if (reference) {
      reference.current = event.target.value;
    }
    setValue(event.target.value);

  }

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  }

  const mapEnum = (props, key) => {
    if ('scenarioId' in props) {
      if (props.scenarioId == "goalTypes") {
        return SCENARIOCROK_ENUMS[props.scenarioId][key]
      } else {  // Handle tags
        return key
      }
    } else {
      return key
    }
  }

  const render = (selected) => {

    if (selected.length === 0) {
      return <em style={{paddingTop: '25px', paddingBottom: '25px'}}>Nothing selected</em>
    }
    return <div className={classes.chips}>
      {selected.map((val) => (
        <Chip size='small' key={val} label={val}></Chip>
      ))}
    </div>
  }
  return (
    <Grid item>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} md={4}>
          <Typography className={classes.typography}>{props.label}</Typography>
        </Grid>
        <Grid item xs={7} md={7}>
          <Select
            data-testid={`${props.id}`}
            multiple={props.multiple}
            renderValue={props.multiple ? render : null}
            displayEmpty
            className={classes.select}
            MenuProps={menuProps}
            value={value}
            onChange={handler}

          >
            <MenuItem value={null} disabled={props.multiple ? true : false}>
              <em>Nothing selected</em>
            </MenuItem>
            {options.current.map((x) => (<MenuItem key={x} value={x}>{
              mapEnum(props, x)
            }</MenuItem>))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  )
}

const CustomSlider = withStyles({
  root: {
    height: 1,
    padding: "15px 0",
  }
})(Slider);

function SlideFilter(props) {
  const classes = useStyles();

  // properties
  const reference = props.reference;
  const options = props.options;

  // states
  const [value, setValue] = React.useState([reference.min.current, reference.max.current]);

  // handler
  const handler = (event, newValue) => {
    if (reference) {
      reference.min.current = newValue[0];
      reference.max.current = newValue[1];
    }

    setValue(newValue);
  }

  if (props.reference.min.current !== value[0] || props.reference.max.current !== value[1]) {
    setValue([reference.min.current, reference.max.current]);
  }

  return (
    <Grid item>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} sm={5} md={4}>
          <Typography className={classes.typography}>{props.label}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} md={7}>
          <CustomSlider
            max={options.max.current}
            value={value}
            onChange={handler}
            valueLabelDisplay="auto"
          ></CustomSlider>
        </Grid>
      </Grid>
    </Grid>
  )
}

function TextFilter(props) {
  /*
    Filter to use for text entry. Based on TextField
   */
  const classes = useStyles();

  // properties
  const reference = props.reference;

  const applyClick = props.applyClick;

  // states
  const [value, setValue] = React.useState(reference.current);

  // handler
  const handler = (event) => {
    if (reference && !event.internal) {
      reference.current = event.target.value;
    }
    setValue(event.target.value);
  }

  const keyPress = (event) => {
    if(event.keyCode == 13){
      applyClick();
    }
  }

  useEffect(() => {
    if (props.reference.current == null || props.reference.current === "") {
      handler({target: {value: ""}, internal: true});
    }
  }, [props]);

  return (
    <Grid item>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} sm={5} md={4}>
          <Typography className={classes.typography}>{props.label}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            fullWidth={true}
            value={value}
            onChange={handler}
            onKeyDown={keyPress}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function ScenarioCrokFilter(props) {
  const classes = baseStyles();

  const options = props.options;
  let values = props.values;
  const count = props.count;
  const applyClick = props.applyClick;
  const resetClick = props.resetClick;
  const search = new URLSearchParams(props.search);

  const [solutionFiltersOpen, setSolutionFiltersOpen] = React.useState(true);
  const [scenarioFiltersOpen, setScenarioFiltersOpen] = React.useState(true);
  const showScenarioFilters = props.showScenarioFilters;

  React.useEffect(() => {
    values = props.values;
  }, [props.values])

  search.forEach((value, key) => {
    if (key in values) {
      if (['metadata__goal_types', 'metadata__tags', 'tags'].includes(key)) {
        values[key].current = value.split(',');
      // } if (['costFunction__icontains'].includes(key)) {
      //  values[key].current = [value,];  // cost function can contain commas
      } else {
        values[key].current = value;
      }
    }
  });

  return (
    <React.Fragment>
      {showScenarioFilters ?
        //scenario filters

        <Grid container direction="column" justify="center" style={{paddingLeft: '5px', paddingRight: '5px'}}>
          <Grid container>
            <Grid><Typography variant="h8" gutterBottom
                              component="div">Total&nbsp;number&nbsp;of&nbsp;tasks:&nbsp;&nbsp;</Typography></Grid>
            <Grid><Typography variant="h8" gutterBottom component="div">{count}</Typography></Grid>
          </Grid>

          <Typography variant="h6" gutterBottom component="div">
            Task Parameters
            <IconButton aria-label="expand row" size="small"
                        onClick={() => setScenarioFiltersOpen(!scenarioFiltersOpen)}>
              {scenarioFiltersOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
          </Typography>
          <Collapse in={scenarioFiltersOpen} timeout="auto" unmountOnExit>
            <TextFilter
              label="(Partial)&nbsp;Task&nbsp;Name"
              reference={values.scenario_id__icontains}
              applyClick={applyClick}  // Give such that enter clicks can trigger filter
            />
            <SelectFilter
              label="Task&nbsp;Version"
              reference={values.version}
              options={options.version}
            />
            <SelectFilter
              multiple
              scenarioId='metadata__tags'
              label="Task&nbsp;Tags"
              reference={values.metadata__tags}
              options={options.metadata__tags}
            />
            <SelectFilter
              multiple
              scenarioId='metadata__goal_types'
              label="Goal&nbsp;Types"
              reference={values.metadata__goal_types}
              options={options.metadata__goal_types}
            />
            <Divider style={{margin: "4px"}}/>
          </Collapse>
        </Grid>

        :
        //solution filters

        <Grid container direction="column" justify="center" style={{paddingLeft: '5px', paddingRight: '5px'}}>
          <Grid container direction="row" justify="right"
                style={{paddingLeft: '5px', paddingRight: '5px', paddingBottom: '15px', paddingTop: '15px'}}
                spacing={2}>
            <Grid><Typography variant="h8" gutterBottom
                              component="div">Total&nbsp;number&nbsp;of&nbsp;solutions:&nbsp;&nbsp;</Typography></Grid>
            <Grid><Typography variant="h8" gutterBottom component="div">{count}</Typography></Grid>

          </Grid>
          <Typography variant="h6" gutterBottom component="div">
            Solution Parameters
            <IconButton aria-label="expand row" size="small"
                        onClick={() => setSolutionFiltersOpen(!solutionFiltersOpen)}>
              {solutionFiltersOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
          </Typography>
          <Collapse in={solutionFiltersOpen} timeout="auto" unmountOnExit>
            <SelectFilter
              label="Solution&nbsp;Version"
              reference={values.version}
              options={options.version}
            />
            <SelectFilter
              solutionId="costFunction__icontains"
              label="Solution&nbsp;Cost&nbsp;Function"
              reference={values.costFunction__icontains}
              options={options.costFunction__icontains}
            />
            <SelectFilter
              multiple
              solutionId="tags"
              label="Solution&nbsp;Tags"
              reference={values.tags}
              options={options.tags}
            />
            <TextFilter
              label="User&nbsp;Name"
              reference={values.user__username}
              applyClick={applyClick}  // Give such that enter clicks can trigger filter
            />
            <TextFilter
              label="Partial&nbsp;User&nbsp;Name"
              reference={values.user__username__icontains}
              applyClick={applyClick}  // Give such that enter clicks can trigger filter
            />
            <Divider style={{margin: "4px"}}/>
          </Collapse>
        </Grid>
      }

      <Grid container direction={"row"} spacing={1} style={{marginTop: '10px'}} alignItems="center">
        <Grid item>
          <Button className={classes.button} variant={"contained"} onClick={applyClick}>Apply Filter</Button>
        </Grid>
        <Grid item>
          <Button className={`${classes.button} ${classes.buttonDelete}`} variant={"contained"} onClick={resetClick}>Reset
            Filter</Button>
        </Grid>
        <Grid item>
          <Typography className={classes.typography}>To share your filters copy the current URL.</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
