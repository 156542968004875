import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import baseStyles from "../../css/const";
import ScenarioCrokService from "../../services/api/scenariocrok-service";
import ScenarioRankingTable from "../../components/solutioncrok/scenario-ranking-table";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  button: {
    // marginRight: "10px",
    marginTop: "10px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function ScenarioRankingContainer(props) {
  const classes = baseStyles();

  // table states
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [scenarios, setScenarios] = React.useState([]);
  //const [scenarioIds, setScenarioIds] = React.useState([]);
  const [count, setCount] = React.useState(0);
  //const isPublic = props.public;


  /*
  // get all scenario Ids
  React.useEffect(() => {
    ScenarioCrokService.getAllScenarioIds()
      .then((response) => {
        setScenarioIds(response.data.scenario_ids);
      })
      .catch((error) => {
        return error;
      });

  }, );

   */

  const setParams = (values = {}) => {
    const params = {
      page: page + 1,
      page_size: rowsPerPage,
    };
    return params;
  };

  // get solutions by solutions id
  React.useEffect(() => {
    let params = setParams();
    ScenarioCrokService.getScenariosForRanking(params)
      .then((response) => {
        setCount(response.data.count)
        setScenarios(response.data.results);
      })
      .catch((error) => {
        return error;
      });
  },[page, rowsPerPage])

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    //setScenariosLoading(true);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    //setScenariosLoading(true);
  };
    return (
      <React.Fragment>
          <ScenarioRankingTable
            scenarios={{scenarios}}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
      </React.Fragment>
    );
}
