import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Grid, Button, Divider, Checkbox } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import baseStyles from "../../css/const";
//import useParentWindow from '../../hooks/use-parent-window';
import createTheme from "@material-ui/core/styles";
import useParentWindow from "../../hooks/use-parent-window";
import SolutionCrokService from "../../services/api/solutioncrok-service";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& > *": {
      borderBottom: "unset",
    },
  },

  line: {
    borderBottom: "1px solid #ddd",
  },
}));

export default function ScenarioRankingRow(props) {
  const baseClasses = baseStyles();
  const classes = useStyles();
  const is_ranking_table = props.is_ranking_table;

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  function handleIframeLoad() {  // Called once iframe source is loadded
    setLoading(false);
  };

    return (
      <React.Fragment>

        <TableRow className={classes.root}>
          <TableCell style={{ paddingRight: "0px", width: "40px" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell><Typography variant="h7" style={{fontFamily: "monospace", color: "rgba(0, 0, 0, 0.87)"}}>{props.scenario_id}</Typography></TableCell>
          <Hidden xsDown>
            <TableCell>{props.version}</TableCell>
          </Hidden>

          <Hidden smDown>
            {is_ranking_table?
              <TableCell>{props.submission_count}</TableCell> :
              <TableCell>
                {props.goal_types == "" ?
                  "-" : [...new Set(props.goal_types)].join(", ")
                }
              </TableCell>
            }
          </Hidden>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
             <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider></Divider>
               <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
              </Box>
              <Grid container spacing={1} direction="row" alignItems="center" justify="center">
                <Grid item sm={12} md={6}>
                  <Box sx={{justifyContent: 'center', alignItems: 'center', width: "100%", height: '500px'}}>
                    {loading ? <CircularProgress/> : null}
                  </Box>
                  <iframe
                      title={props.scenario_id}
                      style={{margin: "0 auto", width: "100%", height: "500px", display: loading ? 'none' : 'block' }}
                      src={props.html}
                      onLoad={handleIframeLoad}
                  ></iframe>
                </Grid>

                <Grid item sm={12} md={6}>
                  <Grid container direction="row" alignItems="center" justify="center" spacing={1} >
                    <Grid item xs={12}>
                     <Link to={`/solutioncrok/ranking/${props.scenario_uuid}`} style={{ marginRight: "10px" }}>
                      <Button className={baseClasses.button} fullWidth={true}>Solution Rankings</Button>
                     </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Button className={baseClasses.button}
                              variant={"contained"}
                              href={props.html}
                              fullWidth={true}
                              target={"_blank"}>
                        Fullscreen Animation
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button className={baseClasses.button}
                              variant={"contained"}
                              href={process.env.REACT_APP_API_URL + "scenariocrok/zip/" + props.id}
                              fullWidth={true}>
                        Download
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: '50px', marginBottom: '25px'}}>
                      <tr className={classes.line}>
                        <th className={classes.line}>Authors</th>
                        <td className={classes.line}>{props.authors}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Affiliation</th>
                        <td className={classes.line}>{props.affiliation}</td>
                      </tr>
                      <tr>
                        <th className={classes.line}>Goal&nbsp;Types</th>
                        <td className={classes.line}>{[...new Set(props.goal_types)].join(", ")}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Tags</th>
                        <td className={classes.line}>{props.tags.join(', ')}</td>
                      </tr>
                    </table>

                  </Grid>
                </Grid>
              </Grid>
             </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );

}
