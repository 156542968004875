import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: "#f5f5f7",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  divider: {
    marginTop: "30px",
    marginBottom: "10px",
  },

  title: {
    fontWeight: "500",
    color: "#404040",
    fontSize: "2.4em",
    textAlign: "left",
    fontFamily: "Roboto",
  },

  subtitle: {
    fontWeight: "500",
    color: "#404040",
    fontSize: "1.7em",
    //textAlign: "left",
    textAlign: "center",
    fontFamily: "Roboto",
    // letterSpacing: 1.5
  },

  paper: {
    height: "100%",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "white",
    square: false,
    variant:"outlined"
  },

  challengePanel: {
    minHeight: "50vh",
  },

  markdown_text: {
    fontSize: "1rem",
  },

  button: {
    outline: "0 !important",
    textDecoration: "none",
    color: "white",
    backgroundColor: theme.palette.primary.dark,
    "&:visited": {
      color: "white",
      textDecoration: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "#DADADA"
    }
  },

  buttonDownload: {
    outline: "0 !important",
    color: "white",
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderRadius: "0.25rem",
    border: "transparent",
    fontSize: "1.1em",
    fontWeight: "400",
    padding:"0.375rem 0.75rem",

  },

  buttonPublish: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: "white",
    },
  },

  buttonEdit: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
      color: "white",
    },
  },

  buttonDelete: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      color: "white",
    },
  },

  buttonCreate: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: "white",
    },
  },

  infoBox: {
    padding: ".6ex",
    paddingLeft: "1.5ex",
    paddingRight: "1.5ex",
    textAlign: "center",
    height: "100%",
    backgroundColor: '#f5f5f7'
  },

  outerLink: {
    textDecoration: "none",
    color: "inherit",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: "black",
    },
  },

  innerLink: {
    textDecoration: "none",
    color: "inherit",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: "black",
    },
  },
}));

export default useStyles;
