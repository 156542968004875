import React, { useRef } from "react";

import {
  Typography,
  Card,
  CardContent,
  Collapse,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  Divider,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import DefaultLayout from "../../components/layouts/default-layout";
import LargeLayout from "../../components/layouts/large-layout";
import QuickAccess from "../../components/quick-access";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import Link from "@material-ui/core/Link";
import SolutionUpload from "../../components/solution/solution-upload";




const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },

  main: {
    width: "100%",
    margin: "0 auto",
  },

  "row-1": {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

  },

  "docker-submissions": {
    marginTop: '20px',
    marginBottom: '20px',
  },

  item: {
    flexBasis: "45%",
  },

  "submission-format": {
    marginTop: "20px",
  },

  "xml-formats": {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  "cost-and-vehicle-models": {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});



function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

export default function SolutionSubmission() {
  // set document title
  const [docTitle, setDocTitle] = useDocTitle("Solution Submission");

  // setup tracker after setting the document title
  useTracker();

  const classes = useStyles();

  return (
    <LargeLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={10} xl={10}>
          <div id="main" className={classes.main}>
            <div id="row-1" className={classes["row-1"]}>
              <div id="column-1" className={classes.item}>
                <Typography variant="h4">Upload Your Solution Here</Typography><br/>
                <SolutionUpload/>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </LargeLayout>
  );
}
