import { Grid, Typography } from "@material-ui/core";
import React from "react";
import SolutionService from "../../services/api/solution-service";
import DefaultLayout from "../../components/layouts/default-layout";
import BenchmarkTable from "../../components/benchmark/benchmark-table";


import baseStyles from '../../css/const';
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { useHistory } from 'react-router-dom';
import SolutionCrokService from "../../services/api/solutioncrok-service";
import SolutionRankingTable from "../../components/solutioncrok/solution-ranking-table";
import ScenarioCrokFilter from "../../components/scenariocrok/scenariocrok-filter";

export default function SolutionRanking(props) {
  const classes = baseStyles();

  const history = useHistory();

  const scenarioUUId = props.match.params.scenarioUUId;
  const [benchmarks, setBenchmarks] = React.useState([]);

  const [title, setTitle] = useDocTitle(`Solution Ranking of ${scenarioUUId}`);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [reset, setReset] = React.useState(false);
  const [apply, setApply] = React.useState(false);
  const [count, setCount] = React.useState(0);

  const [solutions, setSolutions] = React.useState([]);
  useTracker();



  const values = {
    version: React.useRef("2022"), // single select
    costFunction__icontains: React.useRef([]), // multiple select
    tags: React.useRef([]), // multiple select
    user__username: React.useRef(""), // username to match
    user__username__icontains: React.useRef(""), // partial username to match
  };
  const options = {
    version: React.useRef(["2022"]),
    costFunction__icontains: React.useRef([]),
    scenarioName: React.useRef("Unknown"),
    tags: React.useRef([]),
  };

  const setParams = (values) => {
    const join = (arr) => (arr.length === 0 ? null : arr.join(","));
    const params = {
      page: page + 1,
      page_size: rowsPerPage,
      version: values.version.current,
      costFunction__icontains: values.costFunction__icontains.current,
      tags: join(values.tags.current),
      user__username: values.user__username.current,
      user__username__icontains: values.user__username__icontains.current,
    };
    return params;
  };

  const handleApplyButtonClick = () => {
    setPage(0);
    setApply(!apply);
    history.replace({search: ""});
  };
  const handleResetButtonClick = () => {
    for (const key in values) {
      if (Array.isArray(values[key].current)) {
        // array
        values[key].current = [];
      } else if (typeof values[key].current === "string") {
        // string
        values[key].current = null;
      } else if (values[key].current === null) {
        values[key].current = null;
      } else;
    }
    setReset(!reset);
    setPage(0);
    setRowsPerPage(10);
    setReset(!reset);
    history.replace({search: ""});
  };

  //get all options
  React.useEffect(() => {
    SolutionCrokService.getSolutionSummary(scenarioUUId)
      .then((response) => {
        //options.version.current = response.results.version;
        options.costFunction__icontains.current = response.data.costFunction.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });  // Sort alphabetically ignoring case
        options.scenarioName.current = response.data.scenario_name;
        options.tags.current = response.data.tags.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });  // Sort alphabetically ignoring case
        setTitle(`Solution Ranking of ${options.scenarioName.current}`);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }, [apply, reset]);

  //get solutions
  React.useEffect(() => {
    let params = setParams(values);
    // Set url search params to non-empty filters; exclude page, page_size, public
    const params_url = new URLSearchParams(
      Object.entries(params).filter(([key, value]) =>
        value !== '' &&
        value !== null &&
        !["page", "page_size", "public"].includes(key) ));
    history.replace({search: params_url.toString()})
    // Retrieve solutions
    SolutionCrokService.getSolutionToTask(scenarioUUId, params)
      .then((response) => {
        setCount(response.data.count);
        setSolutions(response.data.results);  //TODO seems to not be tolerant with regard to half completed
      })
      .catch((error) => {
        return error;
      });
  }, [apply, reset, page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    //setScenariosLoading(true);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    //setScenariosLoading(true);
  };

  return (
      <DefaultLayout>
        <Grid item>
          <Typography className={classes.subtitle}>
            Ranking of task {options.scenarioName.current}
          </Typography>
          <Typography className={classes.typoContent} variant="body1">
            A list of all solutions to the task {options.scenarioName.current}.
            The button to the left of each solution opens a detail view that shows more information about each solution.
            Especially, this includes an interactive view of the solution which can be
            <ul>
              <li>rotated pressing the primary mouse key.</li>
              <li>moved using the secondary mouse key.</li>
              <li>zoomed using the mousewheel.</li>
              <li>played / paused / skipped through time with Open Controls &rarr; Animations &rarr; default</li>
            </ul>
          </Typography>
        </Grid>
        <ScenarioCrokFilter
          values={values}
          options={options}
          applyClick={handleApplyButtonClick}
          resetClick={handleResetButtonClick}
          showScenarioFilters={false} //set to false to show solution filters
          count={count}
          search={props.location.search}
          />
        <SolutionRankingTable
          solutions={{solutions}}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </DefaultLayout>
  )
}
