import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Grid, Button, Divider, Checkbox } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import baseStyles from "../../css/const";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& > *": {
      borderBottom: "unset",
    },
  },

  line: {
    borderBottom: "1px solid #ddd",
  },
}));

export default function ScenarioRow(props) {
  const baseClasses = baseStyles();
  const classes = useStyles();

  const [open, setOpen] = React.useState(props.expandAll);
  const linkRef = React.createRef();

  React.useEffect(() => {
    setOpen(props.expandAll);
  }, [props.expandAll]);


  if (props.metadata) {
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell style={{ paddingRight: "0px", width: "40px" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {/* <Hidden xsDown>
            <TableCell>
              <img
                src={props.thumbnail}
                alt="thumbnail"
                style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "75px", display: "block", marginRight: "0" }}
              ></img>
            </TableCell>
          </Hidden> */}
          {props.selectable && (
            <TableCell padding="checkbox">
              <Checkbox name={props.scenario_id} onChange={e => props.onSelect(e, props.version)} checked={props.selected} />
            </TableCell>
          )}
          <TableCell><Typography variant="h7" style={{fontFamily: "monospace", color: "rgba(0, 0, 0, 0.87)"}}>{props.scenario_id}</Typography></TableCell>
          <Hidden xsDown>
            <TableCell>{props.version}</TableCell>
          </Hidden>
          <Hidden smDown>
            <TableCell>{props.metadata.time_horizon.toFixed(2)}</TableCell>
          </Hidden>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider></Divider>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
              </Box>
              <Grid container spacing={1} direction="row" alignItems="center" justify="center">
                <Grid item sm={12} md={6}>
                  <img
                    src={props.gif}
                    alt="scenario video"
                    style={{
                      width: "auto",
                      height: "auto",
                      margin: "auto",
                      maxHeight: "300px",
                      maxWidth: "100%",
                      display: "block",
                    }}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <Grid container direction="row" alignItems="center" justify="center">
                    <Grid item xs={12}>
                      <Link to={`/scenarios/${props.scenario_id}/benchmarks`} style={{ marginRight: "10px" }}>
                        <Button className={baseClasses.button} fullWidth={true}>Benchmark Rankings</Button>
                      </Link>
                      <a ref={linkRef} style={{ display: "none" }}>
                        Download URL
                      </a>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: '25px', marginBottom: '25px' }}>
                      <tr className={classes.line}>
                        <th className={classes.line}>Scenario&nbsp;Version</th>
                        <td className={classes.line}>{props.version}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Data&nbsp;Source</th>
                        <td className={classes.line}>{props.metadata.sources.join(', ')}</td>
                      </tr>
                      <tr>
                        <th className={classes.line}>Obstacle&nbsp;Behavior&nbsp;Type</th>
                        <td className={classes.line}>{props.metadata.behavior_type}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Scenario&nbsp;Tags</th>
                        <td className={classes.line}>{props.metadata.tags.join(', ')}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Obstacle&nbsp;Classes</th>
                        <td className={classes.line}>{props.metadata.obstacle_types.join(', ')}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Goal&nbsp;Types</th>
                        <td className={classes.line}>{props.metadata.goal_types.join(', ')}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Scenario&nbsp;Duration</th>
                        <td className={classes.line}>{props.metadata.time_horizon.toFixed(2)} s</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Initial&nbsp;Velocity</th>
                        <td className={classes.line}>{props.metadata.max_initial_velocity.toFixed(2)} m/s</td>
                      </tr>
                      <tr>
                        <th className={classes.line}>No.&nbsp;of&nbsp;Static&nbsp;Obstacles</th>
                        <td className={classes.line}>{props.metadata.static_obstacle_count}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>No.&nbsp;of&nbsp;Dynamic&nbsp;Obstacles</th>
                        <td className={classes.line}>{props.metadata.dynamic_obstacle_count}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>No.&nbsp;of&nbsp;Ego&nbsp;Vehicles</th>
                        <td className={classes.line}>{props.metadata.ego_vehicle_count}</td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>{props.scenario_id}</TableCell>
          <Hidden xsDown>
            <TableCell>{props.version}</TableCell>
          </Hidden>
          <Hidden smDown>
            <TableCell></TableCell>
          </Hidden>
        </TableRow>
      </React.Fragment>
    );
  }
}
