import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    textAlign: 'center',
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
  },
}));

export default function StickyFooter() {
  // TODO styling for mobile usage (text and height etc.)
  const classes = useStyles();
  return (
    <React.Fragment>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography variant="body1">
            Contact us at
            <a target="_blank" rel="noopener noreferrer" href="mailto:cobra@cps.cit.tum.de"> cobra@cps.cit.tum.de</a><br />
            Copyright © 2024 - All Rights Reserved - <a target="_blank" href="https://www.tum.de/en/">Technical University of Munich</a>
          </Typography>
        </Container>
      </footer>
    </React.Fragment>
  );
}
