import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ScenarioRow from "../scenario/scenario-row";
import ScenarioTableSearchField from "../scenario/scenario-table-search-field";
import ScenarioRankingRow from "./scenario-ranking-row";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  button: {
    // marginRight: "10px",
    marginTop: "10px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  line: {
    borderBottom: "1px solid #ddd",
  },
}));



export default function ScenarioRankingTable(props) {
  const classes = useStyles();

  //const downloadable = props.downloadable;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;
  const count = props.count;
  const scenarios = props.scenarios || [];

  const handlePageChange = props.handlePageChange;
  const handleRowsPerPageChange = props.handleRowsPerPageChange;

  return (
    <React.Fragment>
      {/*<Paper>*/}

      <TableContainer>
        <Table  aria-label="collapsible-table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell style={{paddingBottom: "0px" }}>Task&nbsp;Name</TableCell>
              <Hidden xsDown>
                <TableCell style={{paddingBottom: "0px" }}>Version</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell style={{paddingBottom: "0px" }}>Submission&nbsp;Count</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>

          <TableBody style={{
            position: 'relative',
          }}>
            {<Backdrop style={{position: "absolute", zIndex: 1, opacity: 0.3}}></Backdrop>}
            {Object.keys(scenarios)
              .map(function(item) {
                return scenarios[item];
              }).flat()
              .map(function(item){
                const scenarioRankingProp = {
                  scenario_id: item.scenario_id,
                  scenario_uuid: item.id,
                  version: item.version,
                  authors: item.metadata.authors,
                  affiliation: item.metadata.affiliation,
                  goal_types: item.metadata.goal_types,
                  tags: item.metadata.tags,
                  png: item.png,
                  submission_count: item.solution_count,
                  is_ranking_table: true, //Ranking page should be sorted by submission count
                  html: item.html,
                  json: item.json
                };
                return (
                    <ScenarioRankingRow
                      key={`${item.id}`}
                      className={classes.styledRow}
                      {...scenarioRankingProp}/>
                )
              })
            }
          </TableBody>
        </Table>
        <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
      </TableContainer>
      {/*</Paper>*/}
    </React.Fragment>
  );
}
