import React from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles} from "@material-ui/core/styles";
import baseStyles from "../../css/const";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    body: {
        marginTop: 12,
    },
}));


export default function RobotsTable(props){
    const classes = useStyles();
    const {name, description, image, download, urdf} = props;
    const classesBtn = baseStyles();


  return (
    <div>
      <table className={"table"} cellPadding={"left"}>
        <tr align="center">
          <td width="200px">
            <img src={image}
                 style={{width: "500px", height: "400px", objectFit: "scale-down"}}/>
          </td>

          <td>
            <h1 align="center">{name}</h1>
            <p align="center">{description}</p>
            <a href={download}>
              <button className={classesBtn.buttonDownload}>Download</button>
            </a><br/><br/>
            <a href={urdf}>
              <button className={classesBtn.buttonDownload}>Example URDF</button>
            </a>
          </td>
        </tr>
      </table>
    </div>

  )
}