import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoCard from "../../components/cards/info-card";
import tools from "../../json/tools.json";

import baseStyles from "../../css/const";
import Paper from "@material-ui/core/Paper";
import { Button, Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { renderVideo } from "../../components/cards/video";
import { ExampleCode } from "./tool";

export default function GettingStarted() {
  // set document title
  const [title, setTitle] = useDocTitle("Getting Started");

  // setup tracker after setting the document title
  useTracker();

  const classes = baseStyles();

  const render_topic_box = (props) => {
    const tool_page = props.tool_page;
    const title = props.title;
    const text = props.text;
    const code_example = props.code_example;
    const tutorials = props.tutorials;
    const videos = props.videos;

    return (
        <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
          <Grid container justify="left" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>{title}</Typography>
            </Grid>

            <Grid item xs={12} ms={12} md={12}>
              <Typography align="left">{text}</Typography>
            </Grid>

            <Grid item xs={"auto"}>
              <Button component={Link} variant="contained" className={classes.button} to={tool_page}>
                More Information
              </Button>
            </Grid>

            {code_example && (
              <Grid item xs={12}>
                <ExampleCode source={code_example} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container direction="row" justify="left" spacing={3}>
                {videos &&
                videos.map(function (item) {
                  return (
                    <Grid item xs={12} sm={12} md={6}>
                      <Card className={classes.paper} variant={"outlined"} style={{ width: "100%" }}>
                        {renderVideo(item)}
                      </Card>
                    </Grid>
                  );
                })}

                {tutorials.map(function (item) {
                  const paperProps = {
                    title: item.title,
                    link: item.link,
                    description: item.description,
                    bg_image: item.bg_image,
                  };

                  return (
                    <Grid item xs={12} sm={6} md={6}>
                      <InfoCard {...paperProps} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
    );
  };

  return (
    <body className={classes.body}>
      <DefaultLayout>
        <main>
          <Grid container direction="column" justify="center" spacing={2}>
            {/*<Grid>*/}
            <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
              <Grid item xs={12}>
                <Typography className={classes.title}>Getting Started: Tutorials</Typography>
              </Grid>

              <Grid item ms={12} md={12}>
                <Typography align="left">
                  Along with our benchmark, we provide a range of tools each focusing on s different aspect of motion
                  planning for autonomous vehicles. To ease getting started with using the tools, we provide minimal
                  examples and entry-level tutorials. For more advanced tutorials and further information about the
                  tool, please have a look at the corresponding tool pages.
                </Typography>
              </Grid>
            </Paper>

            {/* Commonroad-io*/}
            {render_topic_box({
              tool_page: "/commonroad-io",
              title: "CommonRoad Input-Output",
              text: (
                <Typography>
                  A comprehensive introduction to CommonRoad benchmarks can be found in our&nbsp;
                  <a target="_blank" rel="noopener noreferrer" href="https://mediatum.ub.tum.de/1379638">
                    paper
                  </a>
                  . In short, a benchmark consists of a scenario id, a cost function, and a vehicle model and vehicle
                  type. The CommonRoad Input-Output package provides methods to read, write, and visualize CommonRoad
                  scenarios and planning problems. Furthermore, it can be used as a framework for implementing motion
                  planning algorithms to solve CommonRoad Benchmarks and is the basis for other tools of the CommonRoad
                  Framework. With CommonRoad Input-Output, solutions to benchmarks can be written as xml-files for
                  submitting them to our website.
                </Typography>
              ),
              code_example: tools.commonroad_io.general.code_example,
              tutorials: tools.commonroad_io.tutorials.getting_started,
            })}

            {/* Scenario Designer */}
            {render_topic_box({
              tool_page: "/scenario-designer",
              title: "Scenario Designer: Create and Convert Maps and Scenarios",
              text:
                "Apart from our scenario database, we provide the CommonRoad Scenario Designer for creating " +
                "new scenarios. This tool can convert maps from file formats such as OpenStreetMap and OpenDRIVE. " +
                "Furthermore, it provides a graphical user interface to edit maps and scenarios manually and " +
                "to populate the maps with traffic using the traffic simulator SUMO.",
              videos: tools.commonroad_scenario_designer.video,
              code_example: tools.commonroad_scenario_designer.general.code_example,
              tutorials: tools.commonroad_scenario_designer.tutorials.getting_started,
            })}

            {/* Drivability Checker */}
            {render_topic_box({
              tool_page: "/drivability-checker",
              title: "Drivability Checker",
              text:
                "In the drivability checker toolbox, we provide several tools that are commonly used in motion planning." +
                  " These tools can be used to check the drivability of a trajectory, check for collisions," +
                " or to perform transformations into curvilinear coordinate systems.",
              code_example: tools.motion_planning_utility.general.code_example,
              tutorials: tools.motion_planning_utility.tutorials.getting_started,
            })}

            {/* Route Planner */}
            {render_topic_box({
              tool_page: "/route-planner",
              title: "Route Planner",
              text:
                "The route planner is used to provide high-level guidance for motion-planning algorithms " +
                "and for defining reference paths.",
              code_example: tools.route_planner.general.code_example,
              tutorials: tools.route_planner.tutorials.getting_started,
            })}

            {/* Commonroad Search*/}
            {render_topic_box({
              tool_page: "/commonroad-search",
              title: "CommonRoad Search",
              text:
                "CommonRoad Search provides a selection of exemplary (un)informed search algorithms with motion primitives to solve motion planning problems. Here we also demonstrate how the motion primitives are generated, and how to use our batch processor to search for solutions with parallel execution.",
              code_example: tools.search.general.code_example,
              tutorials: tools.search.tutorials.getting_started,
            })}

            {/* Interactive scenarios*/}
            {render_topic_box({
              tool_page: "/sumo-interface",
              title: "Interactive Scenarios",
              text:
                "In interactive scenarios, other traffic participants react to the behavior of the ego vehicle. This is achieved by coupling CommonRoad with the traffic simulator SUMO. In addition to the CommonRoad-SUMO interface, we provide also interactive versions of CommonRoad scenarios.",
              code_example: tools.sumo.general.code_example,
              tutorials: tools.sumo.tutorials.getting_started,
            })}

            {/*{CommonRoad-RL}*/}
            {render_topic_box({
              tool_page: "/commonroad-rl",
              title: "CommonRoad Reinforcement Learning",
              text:
                "This project contains a software package to solve motion planning problems on CommonRoad using reinforcement learning methods, currently based on OpenAI Stable Baselines.",
              code_example: tools.commonroad_rl.general.code_example,
              tutorials: tools.commonroad_rl.tutorials.getting_started,
            })}
          </Grid>
          {/*</Grid>*/}
        </main>
      </DefaultLayout>
    </body>
  );
}
