import {React,useState, useEffect} from 'react';
import DefaultLayout from '../../components/layouts/default-layout';
import LargeLayout from '../../components/layouts/large-layout';
import NotebookFrame from '../../components/notebook-frame';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';
import tutorials from '../../json/tutorials.json';
import reactfrom from 'react-pdf';
import {object} from "prop-types";
import Grid from "@material-ui/core/Grid";
import baseStyles from "../../css/const";
import {Button} from "@material-ui/core";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import ReactEmbedGist from 'react-embed-gist';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";


export default function CrokDocumentation(props) {
  const [url, setUrl] = useState('');

  const classes = baseStyles();

  useEffect(() => {
    const pdfTitle = props.match.params.title;
    let id = 0;
    if (pdfTitle == "robot")
      id = 2;
    else if (pdfTitle == 'scenario')
      id = 3;
    else if (pdfTitle == 'solution')
      id = 4;
    else if (pdfTitle == 'tutorial') {
      setUrl("https://colab.research.google.com/drive/1omAoEg6tTSXnY4wIfgD9z0JL31TIwxIl#offline=true&sandboxMode=true");
      return;
    }
    pdfload(id)
  }, [props.match.params.title])

  function pdfload(id) {
      axios.get(process.env.REACT_APP_API_URL + `pdfload/${id}`,{
      responseType: 'blob',
    }).then(response => {
      const content = response.headers['content-type'];
      const file = new Blob(
      [response.data],
      {type: "application/pdf"},
      );
      const fileURL = URL.createObjectURL(file);
     // window.open(fileURL);
      setUrl(fileURL);
      //return fileURL;
    }).catch(error => console.log(error));
  }

  function chooseObject() {
    if (url.endsWith("true")) {
      return (
        <Grid container direction="column" justify="center" spacing={2}>
          {/*<Grid>*/}
          <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>CoBRA Tutorials</Typography>
            </Grid>

            <Grid item ms={12} md={12}>
              <Typography align="left">
                Along with our benchmark, we provide a range of tools each focusing on s different aspect of modular
                robot optimization. To ease getting started with using the tools, we provide minimal
                examples and entry-level tutorials. For more advanced tutorials and further information about the
                tool, please have a look at the corresponding tool pages.
              </Typography>
            </Grid>
          </Paper>
          <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>CoBRA I/O</Typography>
            </Grid>

            <Grid item ms={12} md={12}>
              <Typography align="left">
                CoBRA I/O is a python toolbox to interact with the CoBRA benchmark website. It allows to retrieve
                robots and tasks from the CoBRA website and to submit solutions to the benchmark. The toolbox is
                a standalone&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://pypi.org/project/cobra-io/">pypi package</a>.
                It contains a&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://cobra-io.readthedocs.io/en/latest/">documentation</a>,&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://gitlab.lrz.de/tum-cps/cobra-io">git repository</a>, and&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://gitlab.lrz.de/tum-cps/cobra-io/-/issues">bug tracker</a>.<br/>
                The <a target="_blank" rel="noopener noreferrer" href="https://gitlab.lrz.de/tum-cps/cobra-io/-/tree/main/tutorials">tutorials</a>&nbsp;
                for this toolbox are available in the git repository. The first tutorial is also available as a google colab notebook.
                You can run it online by clicking the "Open in Colab" button.
              </Typography>
            </Grid>
            <Grid item ms={12} md={12} margin={20}>
              <ReactEmbedGist gist="kingjin94/9d22e37d0fbcc2ef42d78bca6035983d"
                              loadingClass="loading__screen"
                              titleClass={classes.subtitle}/>
            </Grid>
          </Paper>
          <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>Negative Solutions</Typography>
            </Grid>

            <Grid item ms={12} md={12}>
              <Typography align="left">
                Alongside all the robotic tasks and associated solutions on this website, we provide a set of negative
                solutions, i.e., that are explicitly designed to not fulfill the task's goals and/constraints.
                The current set can be downloaded as a zip file&nbsp;
                <a target="_blank" href={process.env.REACT_APP_API_URL  + "negativesolutions"}>here</a>.
                Each of these solution files contains an extra field <tt>failure</tt> with a (human-readable) explanation
                of why this is not a valid solution.
              </Typography>
            </Grid>
          </Paper>
          <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>Timor Python</Typography>
            </Grid>

            <Grid item ms={12} md={12}>
              <Typography align="left">
                Timor python is a toolbox to create and simulate modular robots with. It integrates with CoBRA by
                implementing most of most of CoBRA's task, robot, and solution classes. These can be used to create
                custom tasks and solutions. It can also verify CoBRA's constraints and goals, and calculate the cost
                of solutions. Timor is also a standalone&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://pypi.org/project/timor-python/">pypi package</a>&nbsp;
                with exhaustive documentation and tutorials.
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      );
    } else {
      return (
        <object data={url}  type="application/pdf" width="100%" height="1000px" align="center" display="flex">
        </object>
      );
    }
  }


  return (
    <DefaultLayout>
      <div>
        {chooseObject()}
      </div>
    </DefaultLayout>
  );
}







