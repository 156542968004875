const SCENARIO_ENUMS = {
  behaviorTypes: {
    INTERACTIVE: 'Interactive Simulation',
    SET_BASED: 'Set-Based Prediction',
    TRAJECTORY: 'Trajectory Prediction'
  },

  goalTypes: {
    VELOCITY: 'Velocity Interval',
    ORIENTATION: 'Orientation Interval',
    TIME_STEP: 'Time Interval',
    LANELET: 'Lanelet Goal Area',
    POSITION: 'Position Goal Area'
  },

  laneletTypes: {
    URBAN : 'Urban',
    COUNTRY : 'Country',
    HIGHWAY : 'Highway',
    DRIVE_WAY : 'Driveway',
    MAIN_CARRIAGE_WAY : 'Main Carriageway',
    ACCESS_RAMP : 'Access Ramp',
    EXIT_RAMP : 'Exit Ramp',
    SHOULDER : 'Shoulder',
    BUS_LANE : 'Bus Lane',
    BUS_STOP : 'Bus Stop',
    BICYCLE_LANE : 'Bicycle Lane',
    SIDEWALK : 'Sidewalk',
    CROSSWALK : 'Crosswalk',
    INTERSTATE : 'Interstate',
    INTERSECTION : 'Intersection',
    UNKNOWN : 'Unknown'
  },

  tags: {
    INTERSTATE : 'Interstate',
    URBAN : 'Urban',
    HIGHWAY : 'Highway',
    COMFORT : 'Comfort',
    CRITICAL : 'Critical',
    EVASIVE : 'Evasive',
    CUT_IN : 'Cut In',
    ILLEGAL_CUTIN : 'Illegal Cut In',
    INTERSECTION : 'Intersection',
    LANE_CHANGE : 'Lane Change',
    LANE_FOLLOWING : 'Lane Following',
    MERGING_LANES : 'Merging Lanes',
    MULTI_LANE : 'Multi Lane',
    ONCOMING_TRAFFIC : 'Oncoming Traffic',
    NO_ONCOMING_TRAFFIC : 'No Oncoming Traffic',
    PARALLEL_LANES : 'Parallel Lanes',
    RACE_TRACK : 'Race Track',
    ROUNDABOUT : 'Roundabout',
    RURAL : 'Rural',
    SIMULATED : 'Simulated',
    SINGLE_LANE : 'Single Lane',
    SLIP_ROAD : 'Slip Road',
    SPEED_LIMIT : 'Speed Limit',
    TRAFFIC_JAM : 'Traffic Jam',
    TURN_LEFT : 'Turn Left',
    TURN_RIGHT : 'Turn Right',
    TWO_LANE : 'Two Lane',
    EMERGENCY_BRAKING : 'Emergency Breaking'
  },

  obstacleTypes: {
    UNKNOWN : 'Unknown',
    CAR : 'Car',
    TRUCK : 'Truck',
    BUS : 'Bus',
    BICYCLE : 'Bicycle',
    PEDESTRIAN : 'Pedestrian',
    PRIORITY_VEHICLE : 'Priority Vehiclee',
    PARKED_VEHICLE : 'Parked Vehicle',
    CONSTRUCTION_ZONE : 'Construction Zone',
    TRAIN : 'Train',
    ROAD_BOUNDARY : 'Road Boundary',
    MOTORCYCLE : 'Motorcycle',
    TAXI : 'Taxi',
    BUILDING : 'Building',
    PILLAR : 'Pillar',
    MEDIAN_STRIP : 'Median Strip',
  },

  roadUser: {
    VEHICLE : 'Vehicle',
    CAR : 'Car',
    TRUCK : 'Truck',
    BUS : 'Bus',
    PRIORITY_VEHICLE : 'Priority Vehicle',
    MOTORCYCLE : 'Motorcycle',
    BICYCLE : 'Bicycle',
    PEDESTRIAN : 'Pedestrian',
    TRAIN : 'Train',
    TAXI : 'Taxi',
  },

  trafficSigns: {
    RIGHT_OF_WAY : 'Right of Way',
    PRIORITY : 'Priority',
    YIELD : 'Yield',
    STOP : 'Stop',
    MAX_SPEED : 'Maximum Speed',
    MIN_SPEED : 'Minimum Speed',
    UNKNOWN : 'Unknown',
  },

  trafficSignsDirections: {
    RIGHT : 'Right',
    STRAIGHT : 'Straight',
    LEFT : 'Left',
    LEFT_STRAIGHT : 'Left Straight',
    STRAIGHT_RIGHT : 'Straight Right',
    LEFT_RIGHT : 'Left Straight',
    ALL : 'All',

  }
}

export default SCENARIO_ENUMS;
