const SCENARIOCROK_ENUMS = {
  goalTypes: {
    AT: 'At',
    REACH: 'Reach',
    RETURN_TO: 'Return to',
    FOLLOW: 'Follow',
    LEAVE: 'Leave',
    PAUSE: 'Pause'
  },
}

export default SCENARIOCROK_ENUMS;
