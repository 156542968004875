import React from 'react';

export default function useTracker() {
    React.useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.text = `
            console.log("Hey, it is me, tracker");
            var _paq = window._paq = window._paq || [];

            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */

            var currentUrl = location.href;
            console.log(currentUrl)
            console.log(document.title)
            _paq.push(['setReferrerUrl', currentUrl]);

           _paq.push(['setCustomUrl', currentUrl]);
           _paq.push(['setDocumentTitle', document.title]);

            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
                var u="https://webstat.srv.tum.de/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '141']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();`;
        
        if (process.env.NODE_ENV !== 'development'){
          document.head.appendChild(script);
        }
        
    }, [])
}