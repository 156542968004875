import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Grid, Button, Divider, Checkbox } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import baseStyles from "../../css/const";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& > *": {
      borderBottom: "unset",
    },
  },

  line: {
    borderBottom: "1px solid #ddd",
  },
}));

export default function SolutionRankingRow(props) {
  const baseClasses = baseStyles();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  function handleIframeLoad() {  // Called once iframe source is loadded
    setLoading(false);
  };


    return (
      <React.Fragment>

        <TableRow className={classes.root}>
          <TableCell style={{ paddingRight: "0px", width: "40px" }}>
           <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
           </IconButton>
          </TableCell>

          <TableCell>{props.username}</TableCell>
          <Hidden xsDown>
            <TableCell>{props.version}</TableCell>
          </Hidden>
          <Hidden smDown>
            <TableCell>{props.date}</TableCell>
          </Hidden>
          <Hidden mdDown>
            <TableCell>{props.tags.join(', ')}</TableCell>
          </Hidden>

          <Hidden mdDown>
            <TableCell>
              <Button className={classes.button}
                  variant={"contained"}
                  href={props.json}
                  target={"solution_" + props.solution_id + ".json"}
                  download={"solution_" + props.solution_id + ".json"}>
                    Download
              </Button>
            </TableCell>
          </Hidden>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
             <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider></Divider>
               <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
              </Box>
              <Grid container spacing={1} direction="row" alignItems="center" justify="center">
                <Grid item sm={12} md={6}>
                  <Box sx={{justifyContent: 'center', alignItems: 'center', width: "100%", height: '500px'}}>
                    {loading ? <CircularProgress/> : null}
                  </Box>

                  <iframe
                     style={{margin: "0 auto", width: "100%", height: "500px", display: loading ? 'none' : 'block' }}
                     src={props.html}
                     onLoad={handleIframeLoad}
                  ></iframe>
                </Grid>

                <Grid item sm={12} md={6}>
                  <Grid item>
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: '50px', marginBottom: '25px'}}>
                      <tr className={classes.line}>
                        <th>Cost&nbsp;Function</th>
                        <td>{props.costFunction}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th>Computation&nbsp;Time</th>
                        <td>{props.computationTime}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th>Cost</th>
                        <td>{props.cost}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th>Notes</th>
                        <td>{props.notes}</td>
                      </tr>
                    </table>
                  </Grid>
                  <Button className={baseClasses.button}
                          variant={"contained"}
                          href={props.html}
                          fullWidth={true}
                          target={"_blank"}>
                    Fullscreen Animation
                  </Button>
                </Grid>
              </Grid>
             </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );

}
