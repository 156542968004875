import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import baseStyles from "../../css/const.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "0 auto",
    marginBottom: "0px",
    backgroundColor: "white",
    boxShadow: "2px 5px 20px -8px rgba(0,0,0,0.2)",
  },
}));

export default function BlogCard(props) {
  const classes = useStyles();
  const classesBtn = baseStyles();
  return (
    <React.Fragment>
      <Card className={classes.root} elevation={0}>
        <CardHeader title={props.title} subheader={props.date} />
        <CardContent>
          <Typography variant="subtitle1">{props.text}</Typography>
        </CardContent>
        <CardActions
          style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", marginTop: "auto" }}
        >
          <Button
            size="small"
            href={props.href}
            style={{marginLeft: "1px", marginBottom: "7px", outline: "none", justifySelf: "flex-end", color:"white"}}
            className={classesBtn.button}
          >
            More Information
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
}
