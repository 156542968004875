import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ResearchCard from "../../components/cards/research-card";

import tools from "../../json/tools.json";
import papers from "../../json/papers.json";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";

export default function Publications() {
    
  // set document title
  const [title, setTitle] = useDocTitle("Publications");

  // setup tracker after setting the document title
  useTracker();

  return (
    <DefaultLayout>
      <main>
        <Grid container alignItems="stretch" justify="left" spacing={3}>
          <Grid item>
            <Typography variant="h4"> Publications with CoBRA </Typography>
            <Typography>Please <a href={"mailto:cobra@cps.cit.tum.de?subject=Missing CoBRA Reference"}>contact us</a> if we have not added your publication with CoBRA yet.</Typography>
          </Grid>
          {Object.keys(tools)
            .map(function (item) {
              return tools[item].papers;
            })
            .flat()
            .map(function (item) {
              const cardProps = {
                title: item.title,
                authors: item.authors,
                BibTex: item.BibTex,
                more: item.more,
                fullText: item.fullText,
              };

              return (
                <Grid
                  item
                  sm={12}
                  md={0}
                  alignContent={"flex-start"}
                  alignItems={"flex-start"}
                >
                  <ResearchCard {...cardProps} />
                </Grid>
              );
            })}
          {papers
            .map(function (item) {
              const cardProps = {
                title: item.title,
                authors: item.authors,
                BibTex: item.BibTex,
                more: item.more,
                fullText: item.fullText,
              };

              return (
                <Grid
                  item
                  sm={12}
                  md={0}
                  alignContent={"flex-start"}
                  alignItems={"flex-start"}
                >
                  <ResearchCard {...cardProps} />
                </Grid>
              );
            })}
        </Grid>
      </main>
    </DefaultLayout>
  );
}
