import React from "react";
import DefaultLayout from "../../components/layouts/default-layout";
import ScenariosContainer from '../scenario/scenarios-container';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';
import StorageService from "../../services/local-storage/storage-service";
import Typography from "@material-ui/core/Typography";
import baseStyles from "../../css/const";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import ScenariosCrokContainer from "./scenarioscrok-container";

export default function ScenariosCrok(props) {

  const [selected, setSelected] = React.useState();
  const classes = baseStyles();
  const [title, setTitle] = useDocTitle('Tasks');
  const user = StorageService.getUserInformation();

  useTracker();
  return (
      <DefaultLayout>
          <Grid container direction={'column'} spacing={1}>
            <div className="row">
              <div className="col">
                <h1 className="display-4">Tasks</h1>
              </div>


            </div>
            <Grid item>
                  <ScenariosCrokContainer {... props}/>
              </Grid>


          </Grid>
    </DefaultLayout>
  )
}
