import React from "react";
import baseStyles from "../../css/const";
import ScenarioCrokTable from "../../components/scenariocrok/scenariocrok-table";
import ScenarioCrokService from "../../services/api/scenariocrok-service";
import ScenarioCrokFilter from "../../components/scenariocrok/scenariocrok-filter";
import {useHistory} from "react-router-dom";

export default function ScenariosCrokContainer(props) {
  const classes = baseStyles();
  const history = useHistory();

  // table states
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [reset, setReset] = React.useState(false);
  const [apply, setApply] = React.useState(false);
  const [scenarios, setScenarios] = React.useState([]);
  //const [scenarioIds, setScenarioIds] = React.useState([]);
  const [count, setCount] = React.useState(0);
  //const isPublic = props.public;

  const values = {
    version: React.useRef("2022"), // single select
    public: React.useRef(), // Boolean
    scenario_id__icontains: React.useRef(""), // partial task ID to match
    metadata__tags: React.useRef([]), // multiple select
    metadata__goal_types: React.useRef([]), // multiple select
  };


  const options = {
    version: React.useRef(["2022"]),
    metadata__tags: React.useRef([]),
    metadata__goal_types: React.useRef([]),
  };

  const setParams = (values) => {
    const join = (arr) => (arr.length === 0 ? null : arr.join(","));
    const params = {
      page: page + 1,
      page_size: rowsPerPage,
      version: values.version.current,
      public: values.public.current,
      scenario_id__icontains: values.scenario_id__icontains.current,
      metadata__tags: join(values.metadata__tags.current),
      metadata__goal_types: join(values.metadata__goal_types.current),
    };
    return params;
  };


  //get all options
  React.useEffect(() => {
    ScenarioCrokService.getScenariosSummary()
      .then((response) => {
        //options.version.current = response.results.version;
        options.metadata__tags.current = response.data.tags.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });  // Sort alphabetically ignoring case
        options.metadata__goal_types.current = response.data.goal_types.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });  // Sort alphabetically ignoring case
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }, [apply, reset]);


  // get scenarios
  React.useEffect(() => {
    let params = setParams(values);
    // Set url search params to non-empty filters; exclude page, page_size, public
    const params_url = new URLSearchParams(
      Object.entries(params).filter(([key, value]) =>
        value !== '' &&
        value !== null &&
        !["page", "page_size", "public"].includes(key) ));
    history.replace({search: params_url.toString()})
    ScenarioCrokService.getScenarios(params)
      .then((response) => {
        setCount(response.data.count);
        setScenarios(response.data.results);
      })
      .catch((error) => {
        return error;
      });
  }, [apply, reset, page, rowsPerPage])

  const handleApplyButtonClick = () => {
    setPage(0);
    setApply(!apply);
    history.replace({search: ""});
  };

  const handleResetButtonClick = () => {
    for (const key in values) {
      if (Array.isArray(values[key].current)) {
        // array
        values[key].current = [];
      } else if (typeof values[key].current === "string") {
        // string
        values[key].current = null;
      } else if (values[key].current === null) {
        values[key].current = null;
      } else ;
    }
    setPage(0);
    setRowsPerPage(10);
    setReset(!reset);
    history.replace({search: ""});
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    //setScenariosLoading(true);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    //setScenariosLoading(true);
  };


  return (
    <React.Fragment>
      <ScenarioCrokFilter
        values={values}
        options={options}
        applyClick={handleApplyButtonClick}
        resetClick={handleResetButtonClick}
        showScenarioFilters={true} //set to true to show scenario filters
        count={count}
        search={props.location.search}
      />
      <ScenarioCrokTable
        scenarios={{scenarios}}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </React.Fragment>
  );


}
